@font-face {
  font-family: "Conv_NoveoSans-Bold";
  src: url("NoveoSans-Bold.eot");
  src: local("☺"), url("NoveoSans-Bold.woff") format("woff"),
    url("NoveoSans-Bold.ttf") format("truetype"),
    url("NoveoSans-Bold.svg") format("svg");
  font-weight: normal;
  font-style: normal;
}

// .carouselSlideHeading {
//   @include heading-underline(
//     $colour: $brand-secondary,
//     $width: 50px,
//     $height: 5px,
//     $justify: left,
//     $margin-top: 1rem,
//     $margin-bottom: 1rem
//   );
// }

// .homeIntro {
//   h3 {
//     @include heading-underline(
//       $colour: $brand-secondary,
//       $width: 50px,
//       $height: 5px,
//       $justify: left,
//       $margin-top: 1rem,
//       $margin-bottom: 1rem
//     );
//   }
// }

.carouselSlideHeading:after {
  content: "";
  display: block;
  margin: 20px 0 0;
  width: 130px;
  height: 10px;
  background-image: url(../assets/underline.svg);
  background-size: contain;
  background-repeat: no-repeat;
}

.homeIntro h3:after,
.homeIntro .basket .total:after,
.basket .homeIntro .total:after,
.homeIntro .basket .total:after,
.basket .homeIntro .total:after {
  content: "";
  display: block;
  margin: 30px 0 0;
  width: 130px;
  height: 10px;
  background-image: url(../assets/underline.svg);
  background-size: contain;
  background-repeat: no-repeat;
}

// $home-intro-colour: white: -  not working
.homeIntro>* {
  color: white;
}

.homeIntroWrapper {
  position: relative;

  &:before {
    content: "";
    display: block;
    margin: 0;
    width: 50%;
    height: 400px;
    background-image: url(../assets/intro_circle.svg);
    background-size: cover;
    background-repeat: no-repeat;
    position: absolute;
    right: 0;
    top: 0;
    z-index: 1;
  }
}

.homeFeatures .homeFeature {
  z-index: 2;
}

.homeFeed .feedsTitle:after {
  content: "";
  display: block;
  margin: 20px 0 30px;
  width: 130px;
  height: 10px;
  background-image: url(../assets/underline.svg);
  background-size: contain;
  background-repeat: no-repeat;
}

.pageFooterWrapper {
  position: relative;

  &:before {
    content: "";
    display: block;
    margin: 0;
    width: 550px;
    max-width: 100%;
    height: 180px;
    background-image: url(../assets/footer_circles.svg);
    background-size: cover;
    background-repeat: no-repeat;
    position: absolute;
    left: 0;
    bottom: 0;
    z-index: 1;
    opacity: 0.5;
    pointer-events: none;
  }
}

.footerBox1 {
  padding-right: 80px;
  z-index: 1;
}

.Newsletter {
  label {
    color: white;
  }
}

[class*=ewsletter].footerBox input {
  z-index: 1;
}

// DES-3675 - JK - Footer social tweaks
// Candidate for new variables
.footerBox .socialIcons {
  margin-bottom: 10px;

  a {
    width: 40px;
    height: 50px;
    border-radius: 0;
    justify-content: flex-start;

    &:before {
      font-size: 1.75rem;
    }
  }
}



//video full width of the banner

.noBanner .carouselSlide.containsVideo {
  max-width: 100%;
}

// Page - Container width embedded video 
.postVideoObjectEmbed.embed-container {
  position: relative;
  width: 100%;
  height: 0;
  padding-bottom: 56.45%;

  iframe {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
  }
}



// Mailchimp setup
.footerBox[class*='ewsletter'] #mc_embed_signup_scroll {
  position: relative;
  display: flex;
  flex-wrap: wrap;

  h2 {
    font-size: $font-size-h5;
  }

  .indicates-required {
    font-size: .75em;
    position: absolute;
    top: 2.5px;
    right: 15px;
    color: rgba($white, 0.65);
  }

  >.mc-field-group {
    display: flex;
    flex-direction: column;
    margin: 0 15px 10px 0;

    &.eMail {
      width: 100%;
    }

    &.lName,
    &.fName {
      width: calc(50% - 15px);

      @media (max-width: map-get($breakpoints, sm)) {
        width: 100%;
      }
    }

    >label {
      margin: 0;
      color: rgba($white, 0.65);
      font-size: $font-size-small;
    }

    @media (max-width: map-get($breakpoints, sm)) {
      width: 100%;
      margin: 0 0 10px 0;
    }
  }

  >.mc-field-group.gdpr-mergeRow {
    font-size: $font-size-small;

    p {
      margin-bottom: 0.25em;
    }
  }

  fieldset.gdprRequired {
    display: flex;

    .av-checkbox {
      margin-right: 10px;
      margin-left: 10px;
    }
  }

  input.cta-button {
    margin-top: 10px;
    background-color: $yellow;
    color: text-contrast($yellow) !important;

    &:hover {
      background-color: darken($yellow, 10%);
    }
  }
}

@media (max-width: map-get($breakpoints, md)) {
  .footerBox1 {
    // display: block;
    // width: 100%;
    padding-right: 0;
  }
}


// Subsites
body.subsite {
  @mixin subsite-colour ($subsite-colour-1, $subsite-colour-2) {

    // Header CTA - Layout
    .mainCallToAction {

      // Only Back to main site button in header
      .cta-button:not(.cta-subsite) {
        display: none;
      }

      // Only facebook icon in header
      ul.socialIcons a:not(.facebook) {
        display: none;
      }
    }

    // back to main site - button
    .cta-subsite {
      background-color: $subsite-colour-2;
      color: text-contrast($subsite-colour-2);

      &:hover {
        background-color: darken($subsite-colour-2, 10%);
      }
    }

    // Header social icons
    .mainCallToAction ul.socialIcons a {
      background-color: $subsite-colour-2;

      &:before {
        color: text-contrast($subsite-colour-2);
      }

      &:hover {
        background-color: darken($subsite-colour-2, 10%);
      }
    }

    // Remove subsite menu
    .menuSub {
      display: none;
    }

    // Nav + Burger - Bavkground
    nav.menuMain,
    .menuMainAlt {
      background-color: $subsite-colour-1;
    }

    // Nav - inner
    .menuMain .subMenu a,
    .subMenu .hasSubmenu>a:after {
      color: $subsite-colour-1;
    }

    // Re-order items
    .contentContainer {
      .headerWrapperSubsite {
        order: 1;
      }

      .headerTextSubsite {
        order: 2;
      }

      .subsiteBody {
        order: 3;
      }

      .subsiteFeedsWrapper {
        order: 4;
      }
    }

    // Subsite Body - layout
    .subsiteBody {
      padding-top: 0;
    }

    // Subsite Title
    .headerTextSubsite .subsiteTitle {
      color: $subsite-colour-2;
    }

    // Feeds Title
    h3.feedsTitle:after {
      background-image: unset;
      background-color: $subsite-colour-1;
    }

    // Feeds - Layout
    .subsiteFeed {
      flex: unset;
      margin: 2rem 0;
    }

    // Feed + Listed item - Hover
    .feedItem:hover,
    .listedPost:hover {

      .feedItemDetailsWrapper,
      .listedPostText {
        background: $subsite-colour-1 !important;
        // h3 a, p { color: text-contrast($subsite-colour-1) !important; }
      }
    }

    // Footer - Background
    .pageFooterWrapper:before {
      display: none;
    }

    .pageFooterWrapper {
      background-color: $subsite-colour-2;
    }

    // Footer - Background + Layouts
    .pageFooter {
      display: flex;
      flex-wrap: wrap;
      max-width: $container-max-width;
      margin: 0 auto;

      .footerBox *:before,
      .footerBox * {
        color: text-contrast($subsite-colour-2);
      }

      .footerBox[class*='ewsletter'] {
        display: none;
      }

      @media (min-width: map-get($breakpoints, md) + 1px) {
        .footerBox {
          width: calc(50% - 30px);
        }
      }
    }

    // Inner pages - overall
    &.subsiteInner {

      // Remove subsite tile
      .headerTextSubsite {
        display: none;
      }

      // Remove sidebars
      .contentBlockWrapper .contentBlock {
        display: block;

        .postAside {
          display: none;
        }
      }

      // Inner content headings
      .postContent h2,
      .postContent h2 a,
      .postContent h3,
      .postContent h3 a,
      .postContent h4,
      .postContent h4 a,
      .postContent h5,
      .postContent h5 a,
      .postContent h6,
      .postContent h6 a {
        color: $subsite-colour-1;
      }

      // CTA Buttons
      .postContent .cta-button {
        background-color: $subsite-colour-1;

        &:hover {
          background-color: darken($subsite-colour-1, 10%);
        }
      }

      blockquote {
        background-color: $subsite-colour-1;
      }

    }

  }

  // Subsite - colour specific
  &.rulku {
    @include subsite-colour($subsite-colour-1: #f2893f,
      $subsite-colour-2: #45963b);

    a.mainLogo {
      background-image: url($assets-path + 'rulku-logo.png');
      width: 520px;
      height: 150px;
    }
  }

  &.island-and-cape {
    @include subsite-colour($subsite-colour-1: #1a4e9d,
      $subsite-colour-2: darken(#87C226, 12.5%));

    a.mainLogo {
      background-image: url($assets-path + 'islandcape-logo.png');
      width: 280px;
      height: 160px;
    }
  }

  &.bukmak {
    @include subsite-colour($subsite-colour-1: #572a21,
      $subsite-colour-2: #1a1a1a);

    a.mainLogo {
      background-image: url($assets-path + 'bukmak-logo.png');
      width: 480px;
      height: 140px;
    }
  }

  &.dinybulu {
    @include subsite-colour($subsite-colour-1: #f6921e,
      $subsite-colour-2: #0799df);

    a.mainLogo {
      background-image: url($assets-path + 'dinybulu-logo.png');
      width: 290px;
      height: 160px;
    }
  }
}

body:not(.subsite) .mainCallToAction .cta-button.cta-subsite {
  display: none;
}


// Sticky sidebar on Desktop
@media (min-width: map-get($breakpoints, md) + 1px) {
  .post .contentBlock .postAside {
    position: sticky;
    top: 20px;
  }
}

@media (min-width: 957px) {
    .homeFeature {
        max-width: calc((100% / 3) - 30px);
    }
}