//
// Environment
//

//
// Config values
//
$platform-name: "arnhemlandprogressaboriginalcorporation";
$assets-path: "../assets/";

//
// Colours
//
$brand-primary: #910f17;
$brand-secondary: #261e1c;
$grey: #251e1c;
$donate-colour: #e8ae22;
$black: #251e1c;
$greybg: #ece9e9;
$yellow: #e8ae22;
// Create greyscale

// Utility colours

//
// Layout
//
$container-max-width: 1230px;
$site-bleed: 20px;
// Breakpoints

// Spacers
$spacer: 1rem;
$spacer-y: $spacer;
$spacer-x: $spacer;
//
// Misc
//
$body-colour: $grey;
//
// Transitions
//

//
// Logo
//
$logo: "ALPALogo.png";
$logo-width: 265px;
$logo-height: 100px;
//$logo-absolute: true;
//
// Typography
//
$font-open-sans: "Open Sans", sans-serif;
$font-family-base: $font-open-sans;
$headings-font-family: "Conv_NoveoSans-Bold", Sans-Serif;
$headings-colour: #58595b;
$font-weight-bold: 900;

$font-size-base: 1rem;
$font-size-large: 1.25em;
$font-size-small: 0.875em;
$font-size-h1: 2.5em;
$font-size-h2: 2em;
$font-size-h3: 1.75em;
$font-size-h4: 1.5em;
$font-size-h5: 1.25em;
$font-size-h6: 1em;


// Links

// Icon font

//
// Buttons
//
$border-radius: 3px;
$btn-border-radius: $border-radius;
$btn-padding-x: 2em;
$btn-padding-y: 1em;
$btn-font-family: $font-open-sans;
$btn-font-weight: $font-weight-bold;
// Donate button
$donate-btn-colour: white;
//
// Social icons
// Documentation: https://fresco-docs.netlify.com/#/header/social-icons/
//
$social-icons-border-radius: 100px;
$social-icons-width: 30px;
$social-icons-height: 30px;
$social-icons-font-size: 1rem;

$social-icons-background-colour: $brand-secondary;
$social-icons-colour: #fff;
$social-icons-hover-colour: $donate-colour;
// Social network colours - use these by changing $social-icons-use-brand-colours to true

// Header specific

// Footer specific social icons
$social-icons-footer-background-colour: transparent;
//
// Share this page
//

//
// Form elements
//

// Field groups

//
// Tables
//

//
// Card
//
$card-border-radius: $border-radius;
$card-heading-colour: #58595b;
$card-hover-image-opacity: 1;
$card-hover-image-scale: 1.1;
$card-hover-details-background-colour: $brand-primary;
$card-hover-heading-colour: white;
$card-border: 0;
// Card text overlay

// Card text over

// Card side by side

// Card hover state

// Event cards

//
// Menu admin
//
$menu-admin-enabled: false;
//
// Header
//

// Header content
$header-content-margin-bottom: 10px;
$header-content-padding-top: 0px;
$header-content-padding-bottom: 0px;
// Tagline

// Main call to action
$cta-btn-gap: 10px;
$cta-btn-background-colour: $brand-secondary;
// Search
$header-search-margin-right: 5px;

// Search - input
$header-search-input-max-width: 180px;
$header-search-input-margin-right: 10px;
$header-search-input-padding: 10px 5px;
$header-search-input-border-colour: #eceeef;

// Search - button
$header-search-button-background-colour: $brand-secondary;
$header-search-button-icon-colour: #fff;
$header-search-button-border-radius: 100px;
$header-search-button-width: 30px;
$header-search-button-height: 30px;
// Search when in nav

// Social icons

//
// Sticky header
//

//
// Navigation
//
$nav-type: normal;
$nav-background-colour: $brand-primary;
$nav-normal-align-items: left;
$nav-top-level-item-colour: #000;
$nav-font-family: $font-open-sans;
// Top level items
$nav-top-level-item-padding: 15px 20px;
$nav-top-level-item-colour: white;
// Submenus

// Burger button

// Nav normal
$nav-normal-margin-bottom: 0;
$nav-normal-max-width: 100%;
$nav-normal-border-radius: 0;
//
// Specifically when no banner present and you have gone for $header-absolute: true
//

//
// Carousel
//
$carousel-border-radius: 0;
$carousel-max-width: 100%;
$carousel-contents-max-width: $container-max-width;
$carousel-image-overlay: linear-gradient(
  to right,
  #000 0%,
  rgba(0, 0, 0, 0) 50%
);
$carousel-details-position-y: center;
$carousel-details-background-colour: transparent;
$carousel-details-box-shadow: none;
$carousel-details-max-width: 550px;
$carousel-heading-colour: #fff;
$carousel-summary-colour: #fff;
$carousel-summary-enabled: true;
$carousel-controls-position-y: center;
$carousel-controls-position-x: split;
$carousel-controls-margin-x: $site-bleed;
$carousel-controls-icon-colour: #fff;
$carousel-read-more-enabled: false;
$carousel-heading-font-size: $font-size-h1;
$carousel-summary-font-size: $font-size-large;

// Carousel controls (left-right buttons)

// Carousel buttons (dots or tabs)

// Carousel buttons dots

// Carousel buttons tabs

// Carousel video play button (before breakpoint, when carousel details present)

// Carousel scroll button

// Carousel after breakpoint

// Banner min-height under sm breakpoint - helps to keep some vertical height at narrow viewports, especially for letter-box aspect ratios

//
// Home intro
//
$home-intro-background-colour: $brand-primary;
$home-intro-font-size: 1rem;
$home-intro-padding-y: $spacer * 3;
$home-intro-margin-top: 0rem;
$home-intro-text-align: left;
$home-intro-colour: white;
//
// Home features
//
$home-features-background-colour: $brand-primary;
$home-features-padding-top: $spacer * 1;
$home-features-padding-bottom: $spacer * 1;
$home-features-cards-entry-animation-prefab: 1;
$home-feeds-cards-entry-animation-prefab: 1;
//
// Impact stats
//

// Heading

// Individual stat

// Figure
$impact-stats-figure-font-family: $font-open-sans;
// Summary

//
// Home feeds
//
$home-feeds-background-colour: $greybg;
// Feeds title

// Feed item

// Event feed items
$feed-item-event-start-date-enabled: false;
$feed-item-event-location-enabled: false;
// Twitter feed

//
// Footer
//
$footer-boxes-count: 3;
$footer-prefab: 5;
$footer-background-colour: $grey;
$footer-link-colour: white;
$footer-headings-font-size: $font-size-h5;

// Footer admin links
$footer-admin-basket-link-enabled: false;
// Newsletter
$newsletter-background-colour: transparent;
$newsletter-heading-colour: white;
$newsletter-colour: white;
$newsletter-heading-text-align: left;
$newsletter-button-background-colour: $brand-primary;
// Newsletter hero - full width form above rest of footer

//
// Context container
//

// Donors list

//
// Posts
//

//
// Header text (container for breadcrumb and page title)
//

//
// Page title
//

//
// Sidebar
//

$sidebar-enabled: true;
$sidebar-layout: adjacent;
$sidebar-mobile-layout: below;
$sidebar-adjacent-width: 300px;
$sidebar-adjacent-gap: $spacer * 5;
$sidebar-background-colour: $white;
$sidebar-padding: 0;

// Associated lists

//
// Listing
//

// Listed post settings

//
// Breadcrumb
//

//
// Blockquote
//

//
// Fundraising
//

// Find a fundraiser widget

// Top fundraisers

//
// Donation form
//

// Donation amounts

//
// Quick giving panel
//

//
// Home quick giving panel
//

// Adjacent homepage quick giving

// Overlay homepage quick giving

//
// Totalisers
//

//
// Comments
//

//
// Blog details
//

//
// FAQs
//

//
// Shop
//

// Departments list

// Product-price

// Product post

//
// Subsite
$subsite-enabled: true;
$subsite-hide-main-header: false;
$subsite-hide-main-tagline: true;
$subsite-hide-header-search: true;
$subsite-hide-main-cta: false;
$subsite-content-max-width: $container-max-width;

// Subsite logo
$subsite-logo: "title";

// // Subsite nav
// $subsite-nav-breakpoint: map-get($breakpoints, lg);
$subsite-nav-max-width: 100%;
$subsite-nav-margin-top: 0;
$subsite-nav-margin-bottom: 0;
// $subsite-nav-background-colour: $nav-background-colour !default;
$subsite-nav-contents-max-width: $container-max-width;
// $subsite-nav-align-items: $nav-normal-align-items !default; // left, center, right
// $subsite-nav-top-level-item-padding: $nav-top-level-item-padding !default;
$subsite-nav-top-level-item-colour: red;
// $subsite-nav-top-level-item-font-family: $nav-top-level-item-font-family !default;
// $subsite-nav-top-level-item-font-size: $nav-top-level-item-font-size !default;
// $subsite-nav-top-level-item-font-weight: $nav-top-level-item-font-weight !default;
// $subsite-nav-top-level-item-background-colour: $nav-top-level-item-background-colour !default;
// $subsite-nav-top-level-item-hover-colour: $nav-top-level-item-hover-colour !default;
// $subsite-nav-top-level-item-hover-background-colour: $nav-top-level-item-hover-background-colour !default;

// $subsite-nav-submenu-width: $nav-submenu-width !default;
// $subsite-nav-submenu-background-colour: $nav-submenu-background-colour !default;
// $subsite-nav-submenu-box-shadow: $nav-submenu-box-shadow !default;
// $subsite-nav-submenu-border-radius: $nav-submenu-border-radius !default;
// $subsite-nav-submenu-item-padding: $nav-submenu-item-padding !default;
// $subsite-nav-submenu-item-colour: $nav-submenu-item-colour !default;
// $subsite-nav-submenu-item-font-family: $nav-submenu-item-font-family !default;
// $subsite-nav-submenu-item-font-size: $nav-submenu-item-font-size !default;
// $subsite-nav-submenu-item-font-weight: $nav-submenu-item-font-weight !default;
// $subsite-nav-submenu-item-hover-colour: $nav-submenu-item-hover-colour !default;
// $subsite-nav-submenu-item-hover-background-colour: $nav-submenu-item-hover-background-colour !default;

//
// Cookie consent
//
