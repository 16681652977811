//
// Home features
//

// Choose your overall home feature cards layout
.homeFeatures {
  @include cards-layout-prefab($number-of-cards: 3, $prefab: 1);
}

// Choose your card style for home feature cards
.homeFeatures .homeFeature {
  @include card(
    $card-heading-colour: white,
    $card-details-background-colour: transparent,
    $card-summary-colour: white,
    $card-hover-heading-colour: white,
    $card-hover-summary-colour: white
  );
  @include card-basic;
}

// Alternatively, you can target specific features rather than styling them all
// If you do this, you will need to target them ALL uniquely, so:
// .homeBox1 {...}, .homeBox2 {...}, .homeBox3 {...} etc.

// .homeBox1,
// .homeBox2 {
//   @include card(
//     $card-text-align: left,
//     $card-border: 0,
//     $card-details-background-colour: transparent,
//     $card-summary-colour: #fff,
//     $card-heading-colour: #fff,
//     $card-hover-details-background-colour: transparent,
//     $card-hover-summary-colour: #fff,
//     $card-summary-enabled: false
//   );
//   @include card-text-overlay($card-text-overlay-show-summary-on-hover: false);
// }

// .homeBox3 {
//   @include card(
//     $card-details-background-colour: white,
//     $card-heading-colour: $grey,
//     $card-summary-colour: text-contrast($card-details-background-colour),
//     $card-details-padding: $spacer * 6 $spacer * 4,
//     $card-hover-details-background-colour: white,
//     $card-hover-summary-colour: $grey
//   );
//   @include card-side-by-side($card-side-by-side-image-width: 50%);
// }

//
// Home feeds
//

// Choose your overall home feeds layout
@include home-feeds-prefab($number-of-feeds: 2, $prefab: 2);

// Specify a card layout for each individual feed
.homeFeedBox1:not(.subsiteFeed) .feedList {
  @include cards-layout-prefab(
    $number-of-cards: 4,
    $prefab: 7
  );
  .feedItem {
    @include card;
    @include card-basic;

    &:first-of-type {
      @include card;
      @include card-side-by-side ($card-side-by-side-image-position: left,
        // $card-side-by-side-image-width: 31.75%,
        $card-side-by-side-image-width: 65.75%,
        $card-side-by-side-grow-image: true,
        $card-side-by-side-vertical-text-position: top,
        $card-side-by-side-breakpoint: map-get($breakpoints, lg),
      );
      @media (min-width: map-get($breakpoints, lg) +  1px) {
        .feedItemDetailsWrapper  {
          padding: $spacer * 2 !important;
        }
      }
    }
  }
}

.homeFeedBox2:not(.subsiteFeed) .feedList {
  @include cards-layout-prefab(
    $number-of-cards: 3,
    $prefab: 1
  );
  .feedItem {
    @include card;
    @include card-basic;
  }
}


// Subsite Feeds - General
.subsiteFeed:nth-of-type(odd) .feedList {
  @include cards-layout-prefab(
    $number-of-cards: 3,
    $prefab: 2
  );
}
.subsiteFeed:nth-of-type(even) .feedList {
  @include cards-layout-prefab(
    $number-of-cards: 4,
    $prefab: 2
  );
}
// Subsite Card - General
.subsiteFeed .feedItem {
  @include card;
  @include card-basic;
}

// Subsite Feeds - Specific
body.rulku-enterprises .subsiteFeed:nth-of-type(even) .feedList {
  @include cards-layout-prefab(
    $number-of-cards: 3,
    $prefab: 1
  );
}


//
// Listed posts
//

// Choose your card style for listed post cards
// (not products, search result or embedded map item)
.listedPost:not(.listedProduct):not(.listedSearchResult):not(.listedMapItem) {
  @include card;
  @include card-basic;
}

//
// Listed products
//

// If these are exactly the same as your regular 'listed posts' above, you can remove the ':not(.listedProduct)' selector above and squash this together into more efficient CSS output
.listedProduct {
  @include card;
  @include card-basic;
}
